const savedFeatureFlagsKey = "savedFeatureFlags";

export const FeatureFlags = {
    dbCopilot: "feature.dbcopilot"
}

export function isFeatureEnabled(featureFlag: string) {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get(featureFlag);
    return myParam === "true";
}

export function initializeFeatureFlags() {
    const savedUrlParams = getSavedUrlParams();
    const currUrlParams = new URLSearchParams(window.location.search);

    if (savedUrlParams.toString() === currUrlParams.toString()) {
        return;
    }

    const mergedParams = mergeUrlParams(savedUrlParams, currUrlParams);
    sessionStorage.setItem(savedFeatureFlagsKey, mergedParams.toString());

    updateUrl(mergedParams);
}

function getSavedUrlParams() {
    const savedFeatureFlags = sessionStorage.getItem(savedFeatureFlagsKey) ?? "";
    return !!savedFeatureFlags
        ? new URLSearchParams(savedFeatureFlags)
        : new URLSearchParams("");
}

function mergeUrlParams(savedParams: URLSearchParams, currParams: URLSearchParams) {
    for (const [key, value] of currParams) {
        savedParams.set(key, value);
    }

    return savedParams;
}

function updateUrl(mergedParams: URLSearchParams) {
    const url = new URL(window.location.href);
    for (const [key, value] of mergedParams) {
        url.searchParams.set(key, value);
    }

    window.history.pushState(null, '', url.toString());
}