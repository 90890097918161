import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./Components/NotFound";
import Layout from "./Layout/Layout";
import Product from './Product/Product';
import { initializeIcons } from "@fluentui/react";
import { initializeFeatureFlags } from "./FeatureFlags";

initializeIcons();

const Home = React.lazy(() => import("./Home/Home"));
const Products = React.lazy(() => import("./Products/Products"));
const Extensions = React.lazy(() => import("./Extensions/Extensions"));
const Extension = React.lazy(() => import("./Extension/Extension"));
const Blade = React.lazy(() => import("./Blade/Blade"));
const Lighthouse = React.lazy(() => import("./BladeLighthouse/BladeLighthouse"));
const TestOverview = React.lazy(() => import("./TestHub/TestOverview"));
const TestHubHome = React.lazy(() => import("./TestHub/TestHubHome"));
const Runners = React.lazy(() => import("./TestHub/Runners/Runners"));
const DeploymentGatesHome = React.lazy(() => import("./DeploymentGates/DeploymentGatesHome"));
const DeploymentGatesDetails = React.lazy(() => import("./DeploymentGates/DeploymentGateDetails"));
const BladeLoadTelemetryDetails = React.lazy(() => import("./DeploymentGates/BladeLoadTelemetryDetails"));
const TestRunOverview = React.lazy(() => import("./TestHub/TestRunOverview"));
const PullRequest = React.lazy(() => import("./PullRequests/PullRequest"));
const UnitTestCoverage = React.lazy(() => import("./CodeCoverage/Main"))
const ScenarioTest = React.lazy(() => import("./ScenarioTests/ScenarioTest"));
const ScenarioTestCase = React.lazy(() => import("./ScenarioTests/ScenarioTestCase/ScenarioTestCase"));
const ScenarioTestResult = React.lazy(() => import("./ScenarioTests/ScenarioTestResult/ScenarioTestResult"));
const ScenarioTestGateViewer = React.lazy(() => import("./ScenarioTests/ScenarioTestGateViewer"));
const ScenarioTestSummary = React.lazy(() => import("./ScenarioTests/ScenarioTestSummary/ScenarioTestSummary"));
const PartnerTestsMain = React.lazy(() => import("./PartnerTests/Main"));
const PartnerTestsDashboard = React.lazy(() => import("./PartnerTests/Dashboard"));
const PartnerTestsCreateRelease = React.lazy(() => import("./PartnerTests/CreateRelease"));
const PartnerTestsOnboarding = React.lazy(() => import("./PartnerTests/Onboarding"));
const BuildAndDeployment = React.lazy(() => import("./BuildAndDeployment/BuildAndDeployment"));
const BuildResultAndDeployment = React.lazy(() => import("./BuildAndDeployment/Builds/BuildOverview"));
const Controllers = React.lazy(() => import("./Controllers/Controllers"));
const Session = React.lazy(() => import("./Session/Session"));
const User = React.lazy(() => import("./User/User"));
const DtSecurityWave1 = React.lazy(() => import("./Security/DtSecurityWave1/DtSecurityWave1"));

export const testHubRoute = "/iaasexp/testhub";

const App = () => {
    const [title, setTitle] = React.useState<string>("Loading...");

    React.useEffect(() => {
        document.title = title + " - Extension Analyzer";
        initializeFeatureFlags();
    }, [title]);

    return (
        <Layout title={title}>
            <Suspense fallback="Loading...">
                <Switch>
                    <Route exact path="/" render={(props) => <Home {...props} setTitle={setTitle} />} />
                    <Route exact path="/products" render={(props) => <Products {...props} setTitle={setTitle} />} />
                    <Route exact path="/products/:productName" render={(props) => <Product {...props} setTitle={setTitle} />} />
                    <Route exact path="/extensions" render={props => <Extensions {...props} setTitle={setTitle} />} />
                    <Route exact path="/extensions/:extensionName" render={props => <Extension {...props} setTitle={setTitle} />} />
                    <Route exact path="/extensions/:extensionName/blades/:bladeName" render={props => <Blade {...props} setTitle={setTitle} />} />
                    <Route exact path="/extensions/:extensionName/lighthouse/:bladeName" render={props => <Lighthouse {...props} setTitle={setTitle} />} />
                    <Route path={"/iaasexp/coverage/:coverageType"} render={props => <UnitTestCoverage setTitle={setTitle} {...props} />} />
                    <Route exact path={testHubRoute} render={props => <TestHubHome {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/ctestdash" render={props => <TestOverview {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/ctestdash/testrun/:jobId" render={props => <TestRunOverview {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/pullRequests/:prId" render={props => <PullRequest {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/deploymentgates" render={props => <DeploymentGatesHome {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/deploymentgates/:buildNumber/extension/:extension" render={props => <DeploymentGatesDetails {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/deploymentgates/:buildNumber/extension/:extension/stage/:stage/timestamp/:timestamp" render={props => <BladeLoadTelemetryDetails {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/scenarioTests" render={props => <ScenarioTest {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/scenariotestresult/suites/:suite([^/]*/?[^/]+)/runs/:runId/environments/:environment/builds/:build/testcases/:testCaseName/testlogs/:testLogFileName" render={props => <ScenarioTestResult {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/scenariotestresult/suites/:suite([^/]*/?[^/]+)/runs/:runId/environments/:environment/builds/:build/testcases/:testCaseName/testlogs/:testLogFileName/retries/:retry" render={props => <ScenarioTestResult {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/scenariotestresult/testcases/:testCaseName" render={props => <ScenarioTestCase {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/scenarioTests/:runId" render={props => <ScenarioTest {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/scenarioTestResult" render={props => <ScenarioTestSummary {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/buildAndDeployments" render={props => <BuildAndDeployment {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/buildNumber/:buildNumber/buildId/:buildId" render={props => <BuildResultAndDeployment {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/runners" render={props => <Runners {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/controllers" render={props => <Controllers {...props} setTitle={setTitle} />} />
                    <Route exact path="/iaasexp/scenariotestgateviewer" render={props => <ScenarioTestGateViewer {...props} setTitle={setTitle} />} />
                    <Route exact path="/partnertests" render={props => <PartnerTestsMain {...props} setTitle={setTitle} />} />
                    <Route exact path="/partnertests/dashboard" render={props => <PartnerTestsDashboard {...props} setTitle={setTitle} />} />
                    <Route exact path="/partnertests/create-release" render={props => <PartnerTestsCreateRelease {...props} setTitle={setTitle} />} />
                    <Route exact path="/partnertests/onboarding" render={props => <PartnerTestsOnboarding {...props} setTitle={setTitle} />} />
                    <Route exact path="/session" render={(props) => <Session {...props} setTitle={setTitle} />} />
                    <Route exact path="/user" render={(props) => <User {...props} setTitle={setTitle} />} />
                    <Route exact path="/security/dtWave1" render={(props) => <DtSecurityWave1 {...props} setTitle={setTitle} />} />
                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </Layout>
    );
};

export default React.memo(App);
